import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

if (window.config.newRelic.isEnabled){
    const options = {
        init: {
            distributed_tracing: {
                enabled: true
            },
            privacy: {
                cookies_enabled: false
            },
            ajax: {
                deny_list: ["bam.eu01.nr-data.net"]
            }
        }, // NREUM.init
        info: {
            beacon: "bam.eu01.nr-data.net",
            errorBeacon: "bam.eu01.nr-data.net",
            licenseKey: window.config.newRelic.licenseKey,
            applicationID: window.config.newRelic.applicationID,
            sa: 1
        }, // NREUM.info
        loader_config: {
            accountID: window.config.newRelic.accountID,
            trustKey: "1356230",
            agentID: window.config.newRelic.agentID,
            licenseKey: window.config.newRelic.licenseKey,
            applicationID: window.config.newRelic.applicationID
        } // NREUM.loader_config
    }
    
    // The agent loader code executes immediately on instantiation.
    new BrowserAgent(options)
}
